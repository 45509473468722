import React from "react";

export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <div className="row footer-link"><a className="m-1"
                                                    href="https://voyeurhouse.com/reallifecam">Reallifecam</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/reallifecams">Reallifecams</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/hotscope">Hotscope</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/hotscope">Hotscopes</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/madthumbs">Madthumbs</a><a
                  className="m-1" href="https://voyeurhouse.com/tv">Voyeur
                  House
                  TV</a><a className="m-1"
                           href="https://voyeurhouse.com/webvoyeur">Webvoyeur</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/reallifecam/real-people">Real
                  People</a><a className="m-1"
                               href="https://voyeurhouse.com/reallifecam/voyeur">Voyeur</a><a
                  className="m-1" href="https://voyeurhouse.com/voyeurlife">Voyeur
                  Life</a><a className="m-1"
                             href="https://voyeurhouse.com/spygasm">Spygasm</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/voyeurhouse">Voyeurhouse</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/camsoda">Camsoda</a><a
                  className="m-1" href="https://voyeurhouse.com/myfreecams">My
                  free cams</a><a className="m-1"
                                  href="https://voyeurhouse.com/myfreecams">Live
                  porn</a><a className="m-1"
                             href="https://voyeurhouse.com/downblouse">Downblouse</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/upskirt">Upskirt</a><a
                  className="m-1" href="https://voyeurhouse.com/purenudism">Pure
                  nudism</a><a className="m-1"
                               href="https://voyeurhouse.com/nude">Nude</a><a
                  className="m-1" href="https://voyeurhouse.com/realcam">Real
                  Cam</a><a className="m-1"
                            href="https://voyeurhouse.com/voyeurhit">voyeurhit</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/bottomlessgirls">bottom
                  less girls</a><a className="m-1"
                                   href="https://voyeurhouse.com/chaturbate">chaturbate</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/bouncingboobs">bouncing
                  boobs</a><a className="m-1"
                              href="https://voyeurhouse.com/lesbians">lesbians</a><a
                  className="m-1"
                  href="https://voyeurhouse.com/webcam">webcam</a><a
                  className="m-1" href="https://voyeurhouse.com/homecam">home
                  cam</a><a className="m-1"
                            href="https://voyeurhouse.com/hiddencam">hidden
                  cam</a><a className="m-1"
                            href="https://voyeurhouse.com/spycam">spy
                  cam</a><a className="m-1"
                            href="https://voyeurhouse.com/onlinecams">online
                  cams</a><a className="m-1"
                             href="https://voyeurhouse.com/sexycams">sexy
                  cams</a><a
                  className="m-1" href="https://voyeurhouse.com/livecams">live
                  cams</a><a className="m-1"
                             href="https://voyeurhouse.com/gay">gay</a><a
                  className="m-1" href="https://voyeurhouse.com/camvideos">cam
                  videos</a><a className="m-1"
                               href="https://voyeurhouse.com/xxxstream">xxx
                  stream</a><a className="m-1"
                               href="https://voyeurhouse.com/voyeurstyle">voyeur
                  style</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 <a href="https://voyeurhouse.com/">
              voyeurhouse.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
